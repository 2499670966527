<template>
  <div :style="{ padding: '200px 100px' }">
    <div>
      <h4>PDF Uploader</h4>

      <PDFUploader :update="documentsUpdated" />
    </div>
  </div>
</template>

<script>
import PDFUploader from '@/components/Fields/PDFUploader'

export default {
  components: {
    PDFUploader
  },
  methods: {
    documentsUpdated({ documents }) {
      console.log('documentsUpdated', { documents })
    }
  }
}
</script>
